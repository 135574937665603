import { Card, makeStyles, Menu, styled } from '@material-ui/core';
import ActivitiesIcon from '@material-ui/icons/EmojiPeople';
import DownloadIcon from '@material-ui/icons/GetApp';
import UsersIcon from '@material-ui/icons/Person';
import { cloneElement, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Datagrid,
  DateInput,
  ExportButton,
  Filter,
  ListBase,
  ListToolbar,
  NumberField,
  ReferenceField,
  SelectInput,
  TextField,
  TopToolbar,
  useDataProvider,
  useGetList,
  useListContext
} from 'react-admin';
import { activitiesExporter, usersExporter } from '../../../utils';
import ReportField from '../../custom/ReportField';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '40px'
  },
  wrapper: {
    
  },
  items: {
    fontFamily: 'Poppins',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '100%'
  },
  title: {
    margin: '1em'
  },
  form: {
    display: 'flex',
    justifyContent: 'center'
  },
  inlineField: {
    display: 'inline-block',
    width: '50%'
  }
}));

const StyledMenu = styled(Menu)({
  '& .MuiList-root': {
    padding: 8
  }
});

function ListReportsActions(props) {
  const { filter, filterValues } = useListContext();
  return (
    <TopToolbar {...props}>
      {cloneElement(props.filters, { context: 'button' })}
      <ExportButton
        disabled={false}
        exporter={usersExporter({ filter, filterValues })}
        icon={<UsersIcon />}
        label="Exportar voluntários"
      />
    </TopToolbar>
  );
}

function ListReportsFilters(props) {
  const { filterValues, setFilters } = useListContext();

  const states = useGetList(
    'states',
    { page: 1, perPage: -1 },
    { field: 'name', order: 'ASC' }
  );

  const [cities, setCities] = useState([]);

  const dataProvider = useDataProvider();

  const getCities = useCallback(
    async id => {
      if (id) {
        const { data } = await dataProvider.getOne('states', { id });
        delete data.id;
        setCities(Object.values(data));
      } else {
        setCities([]);
      }
    },
    [dataProvider]
  );

  const handleStateChange = useCallback(
    e => {
      setFilters({ ...filterValues, state: e.target.value, city: undefined });
      getCities(e.target.value);
    },
    [filterValues, getCities, setFilters]
  );

  useEffect(() => {
    if (filterValues.state) {
      getCities(filterValues.state);
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Filter {...props}>
      <DateInput alwaysOn label="Filtrar por data inicial" source="startDate" />

      <DateInput alwaysOn label="Filtrar por data final" source="endDate" />

      <SelectInput
        allowEmpty
        alwaysOn
        choices={Object.values(states.data)}
        disabled={!states?.ids?.length}
        emptyText="Todos"
        label="Estado"
        onChange={handleStateChange}
        optionValue="code"
        source="state"
      />

      <SelectInput
        allowEmpty
        alwaysOn
        choices={cities}
        disabled={!cities.length}
        emptyText="Todas"
        label="Cidade"
        optionValue="code"
        source="city"
      />
    </Filter>
  );
}

const fields = ['volunteers', 'activities', 'benefited', 'hours', 'finishedActivities', 'participants', 'gestaoCount', 'pontualCount', 'sequencialCount'];

function ListReportsSum() {
  const classes = useStyles();
  const { data, ids } = useListContext();

  const record = ids.reduce(
    (rec, id) => ({
      ...rec,
      ...fields.reduce(
        (obj, field) => ({
          ...obj,
          [field]: obj[field] + data[id][field]
        }),
        rec
      )
    }),
    { volunteers: 0, activities: 0, benefited: 0, hours: 0,  finishedActivities: 0, participants: 0, gestaoCount: 0, pontualCount: 0, sequencialCount: 0 }
  );

  return (
    <div className={classes.root}>
      <ReportField
        color="#00f753"
        icon={require('../../../assets/icon-quantity-volunteers.svg').default}
        label="Voluntários cadastrados"
        record={record}
        source="volunteers"
      />
      
      <div className={classes.wrapper}>
        <ReportField
            color="#f7d600"
            icon={require('../../../assets/icon-quantity-activities.svg').default}
            label="Ações cadastradas"
            record={record}
            source="activities"
          />
          <div className={classes.items}>
            <p>Gestão: <strong>{record.gestaoCount} ações</strong></p>
            <p>Pontual: <strong>{record.pontualCount} ações</strong></p>
            <p>Sequencial: <strong>{record.sequencialCount} ações</strong></p>
          </div>
      </div>

      <ReportField
        color="#f7d600"
        icon={require('../../../assets/icon-quantity-activities.svg').default}
        label="Ações finalizadas"
        record={record}
        source="finishedActivities"
      />
      
      <ReportField
        color="#00f753"
        icon={require('../../../assets/icon-quantity-volunteers.svg').default}
        label="Voluntários participantes"
        record={record}
        source="participants"
      />

      <ReportField
        color="#a800f7"
        icon={require('../../../assets/icon-quantity-benefited.svg').default}
        label="Número de beneficiados"
        record={record}
        source="benefited"
      />
      <ReportField
        color="#00a8f5"
        icon={require('../../../assets/icon-quantity-hours.svg').default}
        label="Número de horas voluntárias"
        record={record}
        source="hours"
      />
    </div>
  );
}

function ExportButtonField({ record }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { filter, filterValues } = useListContext();
  return (
    <>
      <Button
        id="export-button"
        aria-controls="export-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        label="Exportar"
      >
        <DownloadIcon />
      </Button>
      <StyledMenu
        id="export-menu"
        anchorEl={anchorEl}
        elevation={1}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiList-root': { backgroundColor: 'red' } }}
        MenuListProps={{
          'aria-labelledby': 'export-button'
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
      >
        <ExportButton
          disabled={false}
          exporter={activitiesExporter({
            companyId: record.id,
            filter,
            filterValues
          })}
          icon={<ActivitiesIcon />}
          label="Exportar ações"
        />
        <ExportButton
          disabled={false}
          exporter={usersExporter({
            companyId: record.id,
            filter,
            filterValues
          })}
          icon={<UsersIcon />}
          label="Exportar voluntários"
          style={{ marginLeft: 10 }}
        />
      </StyledMenu>
    </>
  );
}

export default function ListReports(props) {
  return (
    <ListBase {...props}>
      <ListToolbar
        actions={<ListReportsActions />}
        filters={<ListReportsFilters />}
      />
      <Card>
        <ListReportsSum />
        <Datagrid>
          <ReferenceField label="Empresa" reference="companies" source="id">
            <TextField source="name" />
          </ReferenceField>
          <NumberField
            align="left"
            textAlign="left"
            label="Voluntários cadastrados"
            source="volunteers"
          />
          <NumberField
            align="left"
            textAlign="left"
            label="Ações cadastradas"
            source="activities"
          />

          {/* <FunctionField
            source="allActivities"
            render={record => record.allActivities.map(item => (
              
              <NumberField
                key={item._id}
                align="left"
                textAlign="left"
                label={item._id}
                source="count"
                value={item.count}
              />
            ))}
          /> */}

          

          <NumberField
            align="left"
            textAlign="left"
            label="Ações finalizadas"
            source="finishedActivities"
          />
          <NumberField
            align="left"
            textAlign="left"
            label="Voluntários participantes"
            source="participants"
          />
          <NumberField
            align="left"
            textAlign="left"
            label="Número de beneficiados"
            source="benefited"
          />
          <NumberField
            align="left"
            textAlign="left"
            label="Número de horas voluntárias"
            source="hours"
          />
          <ExportButtonField
            textAlign="center"
            label="Exportar"
            source="export"
            sortable={false}
          />
        </Datagrid>
      </Card>
    </ListBase>
  );
}
