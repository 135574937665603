import { Card, makeStyles } from '@material-ui/core';
import ActivitiesIcon from '@material-ui/icons/EmojiPeople';
import UsersIcon from '@material-ui/icons/Person';
import { cloneElement, useCallback, useEffect, useState } from 'react';
import {
  DateInput,
  ExportButton,
  Filter,
  ListBase,
  ListToolbar,
  ReferenceInput,
  SelectInput,
  TopToolbar,
  useDataProvider,
  useGetList,
  useListContext
} from 'react-admin';
import { activitiesExporter, usersExporter } from '../../../utils';
import ReportField from '../../custom/ReportField';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  wrapper: {
    
  },
  items: {
    fontFamily: 'Poppins',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '100%'
  },
  title: {
    margin: '1em'
  },
  form: {
    display: 'flex',
    justifyContent: 'center'
  },
  inlineField: {
    display: 'inline-block',
    width: '50%'
  }
}));

function ListCompanyReportsActions(props) {
  const { data, filter, filterValues, ids } = useListContext();
  const record = data[ids[0]];
  return (
    <TopToolbar {...props}>
      {cloneElement(props.filters, { context: 'button' })}
      <ExportButton
        disabled={false}
        exporter={usersExporter({
          companyId: record?.id,
          filter,
          filterValues
        })}
        icon={<UsersIcon />}
        label="Exportar voluntários"
      />
      <ExportButton
        disabled={false}
        exporter={activitiesExporter({
          companyId: record?.id,
          filter,
          filterValues
        })}
        icon={<ActivitiesIcon />}
        label="Exportar ações"
      />
    </TopToolbar>
  );
}

function ListCompanyReportsFilters(props) {
  const { filterValues, setFilters } = useListContext();

  const states = useGetList(
    'states',
    { page: 1, perPage: -1 },
    { field: 'name', order: 'ASC' }
  );

  const [cities, setCities] = useState([]);

  const dataProvider = useDataProvider();

  const getCities = useCallback(
    async id => {
      if (id) {
        const { data } = await dataProvider.getOne('states', { id });
        delete data.id;
        setCities(Object.values(data));
      } else {
        setCities([]);
      }
    },
    [dataProvider]
  );

  const handleStateChange = useCallback(
    e => {
      setFilters({ ...filterValues, state: e.target.value, city: undefined });
      getCities(e.target.value);
    },
    [filterValues, getCities, setFilters]
  );

  useEffect(() => {
    if (filterValues.state) {
      getCities(filterValues.state);
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Filter {...props}>
      <DateInput label="Filtrar por data inicial" source="startDate" />

      <DateInput label="Filtrar por data final" source="endDate" />

      <SelectInput
        allowEmpty
        choices={Object.values(states.data)}
        disabled={!states?.ids?.length}
        emptyText="Todos"
        label="Estado"
        onChange={handleStateChange}
        optionValue="code"
        source="state"
      />

      <SelectInput
        allowEmpty
        choices={cities}
        disabled={!cities.length}
        emptyText="Todas"
        label="Cidade"
        optionValue="code"
        source="city"
      />

      <ReferenceInput
        allowEmpty
        emptyText="Todas"
        label="Equipe"
        reference="teams"
        source="team"
        filter={{
          $limit: -1
        }}
      >
        <SelectInput />
      </ReferenceInput>
    </Filter>
  );
}

function ListCompanyReportsContent() {
  const classes = useStyles();
  const { data, ids } = useListContext();
  return ids.map(id => (
    <Card key={id} className={classes.root}>
      <ReportField
        color="#00f753"
        icon={require('../../../assets/icon-quantity-volunteers.svg').default}
        label="Voluntários cadastrados"
        record={data[id]}
        source="volunteers"
      />

      <div className={classes.wrapper}>
        <ReportField
          color="#f7d600"
          icon={require('../../../assets/icon-quantity-activities.svg').default}
          label="Ações cadastradas"
          record={data[id]}
          source="activities"
        />
        <div className={classes.items}>
        { data[id].allActivities.map(item => (
          <p key={item._id}>{item._id}: <strong>{item.count} ações</strong></p>
        ))}
        </div>
      </div>

      <ReportField
        color="#f7d600"
        icon={require('../../../assets/icon-quantity-activities.svg').default}
        label="Ações finalizadas"
        record={data[id]}
        source="finishedActivities"
      />
      
      <ReportField
        color="#00f753"
        icon={require('../../../assets/icon-quantity-volunteers.svg').default}
        label="Voluntários participantes"
        record={data[id]}
        source="participants"
      />

      <ReportField
        color="#a800f7"
        icon={require('../../../assets/icon-quantity-benefited.svg').default}
        label="Número de beneficiados"
        record={data[id]}
        source="benefited"
      />
      <ReportField
        color="#00a8f5"
        icon={require('../../../assets/icon-quantity-hours.svg').default}
        label="Número de horas voluntárias"
        record={data[id]}
        source="hours"
      />
    </Card>
  ));
}

export default function ListCompanyReports(props) {
  return (
    <ListBase {...props}>
      <ListToolbar
        filters={<ListCompanyReportsFilters />}
        actions={<ListCompanyReportsActions />}
      />
      <ListCompanyReportsContent />
    </ListBase>
  );
}
