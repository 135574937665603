import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import {
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  ReferenceInput,
  SelectInput,
  TextField,
  useDataProvider,
  useGetList,
  useListContext
} from 'react-admin';
import { Route, useHistory } from 'react-router';
// import green from '@material-ui/core/colors/green';
// import red from '@material-ui/core/colors/red';

import { CustomList, StatusField } from '../../custom';

// const activeRowStyle = (record, index, defaultStyle = {}) => ({
//   ...defaultStyle,
//   borderLeftColor: record?.finished ? green[500] : red[500],
//   borderLeftStyle: 'solid',
//   borderLeftWidth: 5
// });

function Filters(props) {
  const { filterValues, setFilters } = useListContext();

  const states = useGetList(
    'states',
    { page: 1, perPage: -1 },
    { field: 'name', order: 'ASC' }
  );

  const [cities, setCities] = useState([]);

  const dataProvider = useDataProvider();

  const getCities = useCallback(
    async id => {
      if (id) {
        const { data } = await dataProvider.getOne('states', { id });
        delete data.id;
        setCities(Object.values(data));
      } else {
        setCities([]);
      }
    },
    [dataProvider]
  );

  const handleStateChange = useCallback(
    e => {
      setFilters({ ...filterValues, state: e.target.value, city: undefined });
      getCities(e.target.value);
    },
    [filterValues, getCities, setFilters]
  );

  useEffect(() => {
    if (filterValues.state) {
      getCities(filterValues.state);
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Filter {...props}>
      <DateInput label="Filtrar por data inicial" source="startDate" />

      <SelectInput
        allowEmpty
        choices={Object.values(states.data)}
        disabled={!states?.ids?.length}
        emptyText="Todos"
        label="Estado"
        onChange={handleStateChange}
        optionValue="code"
        source="state"
      />

      <SelectInput
        allowEmpty
        choices={cities}
        disabled={!cities.length}
        emptyText="Todas"
        label="Cidade"
        optionValue="code"
        source="city"
      />

      <ReferenceInput
        allowEmpty
        emptyText="Todas"
        label="Equipe"
        reference="teams"
        source="team"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{
          $limit: -1
        }}
      >
        <SelectInput />
      </ReferenceInput>

      <BooleanInput label="Finalizada?" source="finished" />

      <SelectInput
        allowEmpty
        choices={[
          { id: false, name: 'Sim' },
          { id: true, name: 'Não' },
        ]}
        emptyText="Indiferente"
        label="Visível no feed"
        source="hidden"
      />
    </Filter>
  );
}

const useStyles = makeStyles(theme => ({
  root: {},
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  listWithDrawer: {
    marginRight: 400
  },
  drawerPaper: {
    zIndex: 100
  }
}));

export default function ListActivities(props) {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push(`/${props.resource}`);
  }, [history, props.resource]);

  return (
    <div className={classes.root}>
      <Route path={`/${props.resource}/:id`}>
        {({ match }) => {
          const isMatch = match?.params?.id && match.params.id !== 'create';
          return (
            <>
              <CustomList
                {...props}
                bulkActionButtons={false}
                className={classNames(classes.list)}
                filters={<Filters />}
                sort={{ field: 'createdAt', order: 'DESC' }}
                title={props.options.label}
              >
                <Datagrid rowClick="show" /*rowStyle={activeRowStyle}*/>
                  <DateField showTime label="Data inicial" source="startDate" />
                  
                  <TextField label="Equipe" source="teamName" />
                  <TextField label="Nome" source="name" />
                  <StatusField
                    textAlign="center"
                    label="Finalizada"
                    source="finished"
                    values={['Sim', 'Não']}
                  />
                  <StatusField
                    textAlign="center"
                    label="Aberta para todas as equipes"
                    source="allTeams"
                    values={['Sim', 'Não']}
                  />
                  <StatusField
                    textAlign="center"
                    label="Visível no Feed"
                    source="hidden"
                    invert
                    values={['Sim', 'Não']}
                  />
                </Datagrid>
              </CustomList>

              
            </>
          );
        }}
      </Route>
    </div>
  );
}
